import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import HookFormCheckbox from '../UI/HookFormCheckbox';
import CreditSubmitButton from '../UI/CreditSubmitButton';
import HookFormLabel from '../UI/HookFormLabel';

const StyledReview = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    h2 {
        font-size: 42px;
        margin-top: 32px;
        padding-bottom: 33px;
        border-bottom: 5px solid #1d2521;
        position: relative;
        width: 133px;
        text-align: left;
        @media screen and (max-width: 768px) {
            font-size: 38px;
        }
    }
    .review-primary {
        width: 800px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 768px) {
            width: calc(100% - 40px);
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
            width: calc(100% - 5rem);
        }
        .btn-group {
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width: 768px) {
                justify-content: space-between;
            }
        }

        & + input[type='submit'] {
            margin: 1em 1em 5em 0;
            font-weight: 600;
            cursor: pointer;
        }
        h4 {
            text-align: left;
            font-size: 30px;
            line-height: 43px;
            margin: 40px 0 32px 0;
            a {
                color: ${props => props.theme.colors.primaryYellow};
                font-family: ${props => props.theme.fonts[1]}, sans-serif;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 29px;
                text-decoration: underline;
                margin: 0 0 0 23px;
            }
            @media screen and (max-width: 768px) {
                font-size: 1.4rem;
            }
        }
        .termsAndConditons {
            color: ${props => props.theme.colors.primaryYellow};
            line-height: inherit;
            text-decoration: none;
            top: -53px;
            position: relative;
            margin-left: 293px;
            font-weight: 700;
            @media screen and (max-width: 768px) {
                top: -30px;
                margin-left: 45px;
            }
        }
        label[for='agreeToTerms'] {
            color: #757575;
            font-family: 'Acumin-Pro';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            font-weight: 700;
        }
    }

    @media screen and (min-width: 1024px) {
        .flex-row {
            display: flex;
            flex-direction: row;
        }

        h2 {
            font-size: 42px;
            align-self: flex-start;
        }
    }
`;

function Review({ onSubmit, submitRef, prevBtnRef, utmParams, ...props }) {
    const { register, handleSubmit, getValues } = useForm({
        values: { ...props },
    });
    const [agreeToTerms, setAgreeToTerms] = useState(false);

    const getValue = (obj, valuefieldname = 'value') =>
        obj && obj[valuefieldname];
    const getLabel = (obj, labelfieldname = 'label') =>
        obj && obj[labelfieldname];

    return (
        <StyledReview onSubmit={handleSubmit(onSubmit)}>
            <div className="review-primary">
                <h2>Review</h2>
                <div className="flex-row">
                    <h4>
                        Company Information
                        <a href="#1" onClick={() => props.onEdit(1)}>
                            Edit
                        </a>
                    </h4>
                </div>
                <input
                    type="hidden"
                    name="UTM_Campaign__c"
                    value={utmParams.campaign}
                    ref={register}
                />
                <input
                    type="hidden"
                    name="UTM_Medium__c"
                    value={utmParams.medium}
                    ref={register}
                />
                <input
                    type="hidden"
                    name="UTM_Source__c"
                    value={utmParams.source}
                    ref={register}
                />
                <div className="flex-row">
                    <HookFormLabel
                        label="Legal Name of Business"
                        value={
                            props.companyForm.company
                                ? props.companyForm.company
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="CompanyLegalName"
                        id="company"
                        value={props.companyForm.company}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Physical Business Address"
                        value={
                            props.companyForm.address
                                ? props.companyForm.address
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="CompanyStreetAddress"
                        value={props.companyForm.address}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="City"
                        value={
                            props.companyForm.city ? props.companyForm.city : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="CompanyCity"
                        value={props.companyForm.city}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="State"
                        value={
                            props.companyForm.state
                                ? getValue(props.companyForm.state)
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="CompanyState"
                        value={getValue(props.companyForm.state)}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Zip Code"
                        value={
                            props.companyForm.zipCode
                                ? props.companyForm.zipCode
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="CompanyZipCode"
                        value={props.companyForm.zipCode}
                        ref={register}
                    />
                </div>
                {props.companyForm.mailingAddressIsDifferent ? (
                    <React.Fragment>
                        <div className="flex-row">
                            <HookFormLabel
                                label="Street Address"
                                value={
                                    props.companyForm.mailingAddress
                                        ? props.companyForm.mailingAddress
                                        : ''
                                }
                            ></HookFormLabel>
                        </div>
                        <div className="flex-row">
                            <HookFormLabel
                                label="City"
                                value={
                                    props.companyForm.mailingCity
                                        ? props.companyForm.mailingCity
                                        : ''
                                }
                            ></HookFormLabel>
                        </div>
                        <div className="flex-row">
                            <HookFormLabel
                                label="State"
                                value={
                                    props.companyForm.mailingState
                                        ? getValue(
                                              props.companyForm.mailingState
                                          )
                                        : ''
                                }
                            ></HookFormLabel>
                        </div>
                        <div className="flex-row">
                            <HookFormLabel
                                label="Zip Code"
                                value={
                                    props.companyForm.mailingZip
                                        ? props.companyForm.mailingZip
                                        : ''
                                }
                            ></HookFormLabel>
                        </div>
                    </React.Fragment>
                ) : null}
                <input
                    type="hidden"
                    name="CompanyDifferentMailingAddress"
                    value={props.companyForm.mailingAddressIsDifferent}
                    ref={register}
                />
                <input
                    type="hidden"
                    name="CompanyMailingStreetAddress"
                    value={props.companyForm.mailingAddress}
                    ref={register}
                />
                <input
                    type="hidden"
                    name="CompanyMailingCity"
                    value={props.companyForm.mailingCity}
                    ref={register}
                />
                <input
                    type="hidden"
                    name="CompanyMailingZipCode"
                    value={props.companyForm.mailingZip}
                    ref={register}
                />
                <input
                    type="hidden"
                    name="CompanyMailingState"
                    value={getValue(props.companyForm.mailingState)}
                    ref={register}
                />
                <div className="flex-row">
                    <React.Fragment>
                        <HookFormLabel
                            label="Phone"
                            value={
                                props.companyForm.mailingPhone
                                    ? props.companyForm.mailingPhone
                                    : ''
                            }
                        ></HookFormLabel>
                    </React.Fragment>
                    <input
                        type="hidden"
                        name="CompanyPhoneNumber"
                        value={props.companyForm.mailingPhone}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Cell Phone"
                        value={
                            props.companyForm.mailingCellPhone
                                ? props.companyForm.mailingCellPhone
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="CompanyCellPhoneNumber"
                        value={props.companyForm.mailingCellPhone}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="SMS Opt In"
                        value={
                            props.companyForm.SMS_Opt_In__c ? 'true' : 'false'
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="SMS_Opt_In__c"
                        value={props.companyForm.SMS_Opt_In__c}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Fax"
                        value={
                            props.companyForm.mailingFax
                                ? props.companyForm.mailingFax
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="CompanyFaxNumber"
                        value={props.companyForm.mailingFax}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Email"
                        value={
                            props.companyForm.mailingEmail
                                ? props.companyForm.mailingEmail
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="CompanyEmail"
                        value={props.companyForm.mailingEmail}
                        ref={register}
                    />
                    <input
                        type="hidden"
                        name="ConfirmCompanyEmail"
                        value={props.companyForm.mailingConfirmEmail}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Tax Exempt?"
                        value={getLabel(props.companyForm.isTaxExempt)}
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="CompanyTaxExempt"
                        value={
                            getValue(props.companyForm.isTaxExempt) === 1 ? 1 : 0
                        }
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Federal ID or BN #"
                        value={
                            props.companyForm.companyFEIN
                                ? props.companyForm.companyFEIN
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="CompanyFEIN"
                        value={props.companyForm.companyFEIN}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="DOT #"
                        value={
                            props.companyForm.DOTNumber
                                ? props.companyForm.DOTNumber
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="CompanyDOTNumber"
                        value={props.companyForm.DOTNumber}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Dun and Bradstreet #"
                        value={
                            props.companyForm.dunBradStreetNumber
                                ? props.companyForm.dunBradStreetNumber
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="CompanyDunBradstreetNumber"
                        value={props.companyForm.dunBradStreetNumber}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <h4>
                        Proprietor, Partners, or Corporate Officers
                        <a href="#2" onClick={() => props.onEdit(2)}>
                            Edit
                        </a>
                    </h4>
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Officer First Name"
                        value={
                            props.peopleForm.OfficerFirstName
                                ? props.peopleForm.OfficerFirstName
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="OfficerOneFirstName"
                        value={props.peopleForm.OfficerFirstName}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Officer Last Name"
                        value={
                            props.peopleForm.OfficerLastName
                                ? props.peopleForm.OfficerLastName
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="OfficerOneLastName"
                        value={props.peopleForm.OfficerLastName}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Officer Title"
                        value={
                            props.peopleForm.OfficerTitle
                                ? props.peopleForm.OfficerTitle
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="OfficerOneTitle"
                        value={props.peopleForm.OfficerTitle}
                        ref={register}
                    />
                </div>
                {/* */}
                <div className="flex-row">
                    <HookFormLabel
                        label="Officer First Name"
                        value={
                            props.peopleForm.OfficerFirstName1
                                ? props.peopleForm.OfficerFirstName1
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="OfficerTwoFirstName"
                        value={props.peopleForm.OfficerFirstName1}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Officer Last Name"
                        value={
                            props.peopleForm.OfficerLastName1
                                ? props.peopleForm.OfficerLastName1
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="OfficerTwoLastName"
                        value={props.peopleForm.OfficerLastName1}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Officer Title"
                        value={
                            props.peopleForm.OfficerTitle1
                                ? props.peopleForm.OfficerTitle1
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="OfficerTwoTitle"
                        value={props.peopleForm.OfficerTitle1}
                        ref={register}
                    />
                </div>
                {/* */}
                <div className="flex-row">
                    <HookFormLabel
                        label="Billing Contact First Name"
                        value={
                            props.peopleForm.BillingContactFirstName
                                ? props.peopleForm.BillingContactFirstName
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="BillingContactFirstName"
                        value={props.peopleForm.BillingContactFirstName}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Billing Contact Last Name"
                        value={
                            props.peopleForm.BillingContactLastName
                                ? props.peopleForm.BillingContactLastName
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="BillingContactLastName"
                        value={props.peopleForm.BillingContactLastName}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Billing Contact Title"
                        value={
                            props.peopleForm.BillingContactTitle
                                ? props.peopleForm.BillingContactTitle
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="BillingContactTitle"
                        value={props.peopleForm.BillingContactTitle}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Phone"
                        value={
                            props.peopleForm.BillingContactPhoneNumber
                                ? props.peopleForm.BillingContactPhoneNumber
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="BillingContactPhoneNumber"
                        value={props.peopleForm.BillingContactPhoneNumber}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Fax"
                        value={
                            props.peopleForm.BillingContactFaxNumber
                                ? props.peopleForm.BillingContactFaxNumber
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="BillingContactFaxNumber"
                        value={props.peopleForm.BillingContactFaxNumber}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Email"
                        value={
                            props.peopleForm.BillingContactEmail
                                ? props.peopleForm.BillingContactEmail
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="BillingContactEmail"
                        value={props.peopleForm.BillingContactEmail}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <h4>
                        EFT Information
                        <a href="#3" onClick={() => props.onEdit(3)}>
                            Edit
                        </a>
                    </h4>
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Bank Account #"
                        value={
                            props.eftForm.bankaccountno
                                ? props.eftForm.bankaccountno
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="EFTRoutingNumber"
                        value={props.eftForm.bankroutingno}
                        ref={register}
                    />
                    <input
                        type="hidden"
                        name="EFTAccountNumber"
                        value={props.eftForm.bankaccountno}
                        ref={register}
                    />
                    <input
                        type="hidden"
                        name="EFTConfirmAccountNumber"
                        value={props.eftForm.confirmbankaccountno}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Contact First Name"
                        value={
                            props.eftForm.contactfirstname
                                ? props.eftForm.contactfirstname
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="EFTFirstName"
                        value={props.eftForm.contactfirstname}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Contact Last Name"
                        value={
                            props.eftForm.contactlastname
                                ? props.eftForm.contactlastname
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="EFTLastName"
                        value={props.eftForm.contactlastname}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Bank Name"
                        value={
                            props.eftForm.bankName ? props.eftForm.bankName : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="EFTBankName"
                        value={props.eftForm.bankName}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Phone"
                        value={
                            props.eftForm.contactphone
                                ? props.eftForm.contactphone
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="EFTPhoneNumber"
                        value={props.eftForm.contactphone}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Bank Routing #"
                        value={
                            props.eftForm.bankroutingno
                                ? props.eftForm.bankroutingno
                                : ''
                        }
                    ></HookFormLabel>
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Fax"
                        value={
                            props.eftForm.contactfax
                                ? props.eftForm.contactfax
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="EFTFaxNumber"
                        value={props.eftForm.contactfax}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Bank Address"
                        value={
                            props.eftForm.bankaddress
                                ? props.eftForm.bankaddress
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="EFTStreetAddress"
                        value={props.eftForm.bankaddress}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="City"
                        value={props.eftForm.city ? props.eftForm.city : ''}
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="EFTCity"
                        value={props.eftForm.city}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="State"
                        value={
                            props.eftForm.state
                                ? getValue(props.eftForm.state)
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="EFTState"
                        value={getValue(props.eftForm.state)}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Zip Code"
                        value={
                            props.eftForm.zipCode ? props.eftForm.zipCode : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="EFTZipCode"
                        value={props.eftForm.zipCode}
                        ref={register}
                    />
                    <input
                        type="hidden"
                        name="EFTAgreement"
                        value={props.eftForm.hasAgreedToTerms}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <h4>
                        Fleet and Vehicle Information
                        <a href="#4" onClick={() => props.onEdit(4)}>
                            Edit
                        </a>
                    </h4>
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Authorized Fleet Contact First Name"
                        value={
                            props.fleetForm.fleetContactFirstName
                                ? props.fleetForm.fleetContactFirstName
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="FleetContactFirstName"
                        value={props.fleetForm.fleetContactFirstName}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Authorized Fleet Contact Last Name"
                        value={
                            props.fleetForm.fleetContactLastName
                                ? props.fleetForm.fleetContactLastName
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="FleetContactLastName"
                        value={props.fleetForm.fleetContactLastName}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Authorized Fleet Contact Title"
                        value={
                            props.fleetForm.fleetContactTitle
                                ? props.fleetForm.fleetContactTitle
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="FleetContactTitle"
                        value={props.fleetForm.fleetContactTitle}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Number of Vehicles"
                        value={
                            props.fleetForm.fleetNumberOfVehicles
                                ? getValue(
                                      props.fleetForm.fleetNumberOfVehicles
                                  )
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="FleetNumberOfVehicles"
                        value={getValue(props.fleetForm.fleetNumberOfVehicles)}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Phone"
                        value={
                            props.fleetForm.fleetContactPhone
                                ? props.fleetForm.fleetContactPhone
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="FleetContactPhoneNumber"
                        value={props.fleetForm.fleetContactPhone}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Cell Phone"
                        value={
                            props.fleetForm.fleetContactCellPhone
                                ? props.fleetForm.fleetContactCellPhone
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="FleetContactCellNumber"
                        value={props.fleetForm.fleetContactCellPhone}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Number of Cards Requested"
                        value={
                            props.fleetForm.fleetNumberOfCardsRequested
                                ? props.fleetForm.fleetNumberOfCardsRequested
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="FleetNumberOfCardsRequested"
                        value={props.fleetForm.fleetNumberOfCardsRequested}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Fax"
                        value={
                            props.fleetForm.fleetContactFax
                                ? props.fleetForm.fleetContactFax
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="FleetContactFaxNumber"
                        value={props.fleetForm.fleetContactFax}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Expected Weekly Usage at Pilot and Flying J (in Dollars $)"
                        value={
                            props.fleetForm.fleetWeeklyUsage
                                ? props.fleetForm.fleetWeeklyUsage
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="FleetWeeklyUsage"
                        value={props.fleetForm.fleetWeeklyUsage}
                        ref={register}
                    />
                    <input
                        type="hidden"
                        name="FleetProductsRequested_gasoline"
                        value={props.fleetForm.fleetProductsRequested_Gasoline}
                        ref={register}
                    />
                    <input
                        type="hidden"
                        name="FleetProductsRequested_other"
                        value={props.fleetForm.fleetProductsRequested_Other}
                        ref={register}
                    />
                    <input
                        type="hidden"
                        name="FleetProductsRequested_def"
                        value={props.fleetForm.fleetProductsRequested_DEF}
                        ref={register}
                    />
                    <input
                        type="hidden"
                        name="FleetProductsRequested_propane"
                        value={props.fleetForm.fleetProductsRequested_Propane}
                        ref={register}
                    />
                    <input
                        type="hidden"
                        name="FleetProductsRequested_additives"
                        value={props.fleetForm.fleetProductsRequested_Additives}
                        ref={register}
                    />
                    <input
                        type="hidden"
                        name="FleetProductsRequested_scales"
                        value={props.fleetForm.fleetProductsRequested_Scales}
                        ref={register}
                    />
                    <input
                        type="hidden"
                        name="FleetProductsRequested_diesel"
                        value={props.fleetForm.fleetProductsRequested_Diesel}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Email"
                        value={
                            props.fleetForm.fleetContactEmail
                                ? props.fleetForm.fleetContactEmail
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="FleetContactEmailAddress"
                        value={props.fleetForm.fleetContactEmail}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Commercial Diesel"
                        value={
                            props.fleetForm.Commercial_Diesel__c
                                ? 'true'
                                : 'false'
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="Commercial_Diesel__c"
                        value={props.fleetForm.Commercial_Diesel__c}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Gasoline"
                        value={props.fleetForm.Gasoline__c ? 'true' : 'false'}
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="Gasoline__c"
                        value={props.fleetForm.Gasoline__c}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Auto Diesel"
                        value={
                            props.fleetForm.Auto_Diesel__c ? 'true' : 'false'
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="Auto_Diesel__c"
                        value={props.fleetForm.Auto_Diesel__c}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Factoring Services"
                        value={
                            props.fleetForm.Freight_Bill_Factoring__c
                                ? 'true'
                                : 'false'
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="Freight_Bill_Factoring__c"
                        value={props.fleetForm.Freight_Bill_Factoring__c}
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Truck Care Maintenance Packages"
                        value={
                            props.fleetForm.Truck_Care_Maintenance_Packages__c
                                ? 'true'
                                : 'false'
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="Truck_Care_Maintenance_Packages__c"
                        value={
                            props.fleetForm.Truck_Care_Maintenance_Packages__c
                        }
                        ref={register}
                    />
                </div>
                <div className="flex-row">
                    <h4>
                        Driver Id
                        <a href="#5" onClick={() => props.onEdit(5)}>
                            Edit
                        </a>
                    </h4>
                </div>
                <div className="flex-row">
                    <HookFormLabel
                        label="Security Code"
                        value={
                            props.additionaInfoForm.securityCode
                                ? props.additionaInfoForm.securityCode
                                : ''
                        }
                    ></HookFormLabel>
                    <input
                        type="hidden"
                        name="SecurityCode"
                        value={props.additionaInfoForm.securityCode}
                        ref={register}
                    />
                </div>
                <input
                    type="hidden"
                    name="Customer_UserName"
                    value={
                        props.peopleForm.OfficerFirstName +
                        ' ' +
                        props.peopleForm.OfficerLastName
                    }
                    ref={register}
                />
                <input
                    type="hidden"
                    name="Customer_Email"
                    value={props.companyForm.mailingEmail}
                    ref={register}
                />
                <HookFormCheckbox
                    name="agreeToTerms"
                    register={register}
                    label="I have read and understand these  "
                    checked={props.agreeToTerms ? true : false}
                    onChange={e => {
                        setAgreeToTerms(e.target.checked);
                    }}
                ></HookFormCheckbox>
                <a
                    className="termsAndConditons"
                    href={props.fuelCardTermsPDF}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    terms and conditions.
                </a>
                <div className="btn-group">
                    <CreditSubmitButton
                        text="PREVIOUS"
                        buttonType="button"
                        buttonref={prevBtnRef}
                        onPrevClick={() => props.onPrevious(getValues())}
                    ></CreditSubmitButton>
                    <CreditSubmitButton
                        id="credit-submit"
                        buttonref={submitRef}
                        text="SUBMIT"
                        agreeToTerms={agreeToTerms}
                    ></CreditSubmitButton>
                </div>
            </div>
        </StyledReview>
    );

    function getText(object, key) {
        var result;

        return (
            Object.keys(object).some(function(k) {
                if (object[k].value == key) {
                    result = result = { text: object[k].text };
                    return true;
                }
            }) && result
        );
    }
    function showSelectedProducts() {
        var newProductsList = [];
        if (props.fleetForm.fleetProductsRequested_Other) {
            newProductsList.push('Other');
        }
        if (props.fleetForm.fleetProductsRequested_Gasoline) {
            newProductsList.push('Gasoline');
        }
        if (props.fleetForm.fleetProductsRequested_Diesel) {
            newProductsList.push('Diesel');
        }
        if (props.fleetForm.fleetProductsRequested_Propane) {
            newProductsList.push('Propane');
        }
        if (props.fleetForm.fleetProductsRequested_DEF) {
            newProductsList.push('DEF');
        }
        if (props.fleetForm.fleetProductsRequested_Scales) {
            newProductsList.push('Scales');
        }
        if (props.fleetForm.fleetProductsRequested_Additives) {
            newProductsList.push('Additives');
        }
        return newProductsList;
    }
}

Review.propTypes = {};

export default Review;
