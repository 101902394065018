import React from 'react';
import styled from 'styled-components';

const StyledDocuSignForm = styled.section`
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    max-width: 1148px;
    align-items: center;

    h2 {
        align-self: flex-start;
        font-size: 1.4rem;
        margin-top: 32px;
        position: relative;
        width: 133px;
        text-align: left;

        @media screen and (min-width: 768px) {
            font-size: 1.875rem;
        }
    }

    p {
        align-self: flex-start;
        margin-bottom: 20px;
    }

    iframe {
        width: 100%;
        height: 500px;
    }
`;
const DocuSignForm = ({ ...props }) => (
    <StyledDocuSignForm>
        <h2>Sign</h2>
        <p>Please review the final form and sign</p>
        <iframe
            id="docusignForm"
            name="docusignForm"
            src={`${process.env.DOCUSIGN_URL}MEMBER/PowerFormSigning.aspx?PowerFormId=${process.env.DOCUSIGN_FORM_ID}&EnvelopeField_LeadOrigin=Website%20Lead%20-%20One9&${props.payload}`}
            frameborder="0"
            title='docusign'
        ></iframe>
    </StyledDocuSignForm>
);

export default DocuSignForm;
