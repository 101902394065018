import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import HookFormInput from '../UI/HookFormInput';
import styled from 'styled-components';
import CreditSubmitButton from '../UI/CreditSubmitButton';

const AdditionalFormStyled = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    h2 {
        font-size: 24px;
        margin-top: 32px;
        margin-bottom: 32px;
        position: relative;
        width: 100%;
        text-align: left;
        @media screen and (max-width: 45em) {
            font-size: 1.4rem;
        }
    }

    .additional-info-primary,
    .additional-mailing-info {
        width: 800px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 45em) {
            width: calc(100% - 40px);
        }
        @media screen and (min-width: 45.5em) and (max-width: 64em) {
            width: calc(100% - 5rem);
        }
        .btn-group {
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width: 768px) {
                justify-content: space-between;
            }
        }
        .form-text {
            &:not(.noTopMargin) {
                margin-top: 32px;
            }
            width: 100%;
            @media screen and (max-width: 45em) {
                align-self: center;
                & ~ h2 .required_text {
                    display: none;
                }
            }
        }
        .flex-row small {
            text-transform: capitalize;
            color: #676767;
            font-size: 10px;
            position: absolute;
            margin: 56px 0 0 400px;
            @media screen and (max-width: 710px) {
                margin: -27px 0 0 0;
            }
        }
        h2 .required_text {
            position: absolute;
            right: 2%;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
            &:before {
                content: '';
                position: relative;
                display: inline-block;
                width: 8px;
                height: 20px;
                background-color: ${props => props.theme.colors.primaryYellow};
                margin-right: 5px;
                top: 5px;
            }
        }
        & + input[type='submit'] {
            margin: 1em 1em 5em 0;
            font-weight: 600;
            cursor: pointer;
        }
    }

    @media screen and (min-width: 1024px) {
        .flex-row {
            display: flex;
            flex-direction: row;
        }

        h2 {
            font-size: 30px;
            align-self: flex-start;
        }
    }

    .additional-mailing-info {
        margin-top: 48px;
        margin-bottom: 48px;
        h2 {
            font-size: 24px;
            margin-top: 32px;
            margin-bottom: 32px;
        }

        @media screen and (min-width: 1024px) {
            h2 {
                font-size: 30px;
                text-align: left;
            }
        }
    }
`;

function AdditionalForm({ onSubmit, submitRef, prevBtnRef, ...props }) {
    const { register, handleSubmit, errors, getValues } = useForm({
        defaultValues: { ...props.additionaInfoForm },
    });
    const [isWantPFJservices, setIsWantPFJservices] = useState(
        props.wantedPFJservices
    );
    useEffect(() => {
        setIsWantPFJservices(props.additionaInfoForm.wantedPFJservices);
    }, [props.additionaInfoForm.wantedPFJservices]);
    return (
        <AdditionalFormStyled onSubmit={handleSubmit(onSubmit)}>
            <div className="additional-info-primary">
                <p className="form-text">
                    Please include Driver ID and Truck Maintenance information
                    below.
                </p>
                <h2>
                    {' '}
                    Driver Id{' '}
                    <strong className="required_text">Required</strong>
                </h2>
                <p className="form-text noTopMargin">
                    This Driver ID will initially be applied to all of the cards
                    that are requested. To ensure protection of the account
                    Customer must change the code for each individual card on
                    the Fleet Card Website (www.pilotfleetcard.com) upon receipt
                    of the cards.
                </p>
                <br />
                <HookFormInput
                    name="securityCode"
                    register={register}
                    hasError={errors.securityCode && true}
                    errorText={
                        errors.securityCode && errors.securityCode.message
                    }
                    label="Security Code"
                    required={true}
                    value={props.securityCode && props.securityCode}
                    defaultValue={props.additionaInfoForm.securityCode}
                    validate={value => {
                        if (value.length < 6 || value.length > 16) {
                            return 'Security Code must be between 6 and 16 digits';
                        }
                    }}
                    maskType="Number"
                ></HookFormInput>
            </div>
            <div className="additional-info-primary">
                <div className="btn-group">
                    <CreditSubmitButton
                        text="PREVIOUS"
                        buttonType="button"
                        buttonref={prevBtnRef}
                        onPrevClick={() => props.onPrevious(getValues())}
                    ></CreditSubmitButton>
                    <CreditSubmitButton
                        text="NEXT"
                        buttonref={submitRef}
                    ></CreditSubmitButton>
                </div>
            </div>
        </AdditionalFormStyled>
    );
}

AdditionalForm.propTypes = {};

export default AdditionalForm;
