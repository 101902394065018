import axios from 'axios';
import queryString from 'querystring';

export const getAuthenticated = (
    authString,
    clientId,
    clientSecret,
    hostURL
) => {
    let authURL = `${hostURL}${authString}`;
    let obj = {
        grant_type: 'client_credentials',
        client_id: clientId,
        client_secret: clientSecret,
        scope: 'squidex-api',
    };

    return new Promise((resolve, reject) => {
        axios
            .post(authURL, queryString.stringify(obj), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(response => {
                resolve(response.data.access_token);
            })
            .catch(function(error) {
                // handle error
                reject(new Error(error));
            });
    });
};

export const getContentFromSquidex = (bearerToken, hostURL, endpoint) => {
    let contentURL = `${hostURL}/api/content/${process.env.SQUIDEX_ENV}/${endpoint}`;

    return new Promise((resolve, reject) => {
        axios
            .get(contentURL, {
                dataType: 'json',
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                },
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(new Error(error));
            });
    });
};
