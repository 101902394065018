import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import HookFormInput from '../UI/HookFormInput';
import HookFormSelect from '../UI/HookFormSelect';
import styled from 'styled-components';
import HookFormCheckbox from '../UI/HookFormCheckbox';
import { motion } from 'framer-motion';
import CreditSubmitButton from '../UI/CreditSubmitButton';
import {
    TaxExempt,
    StateOptions,
} from '../Constants';

const StyledCompanyForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    h2 {
        font-size: 24px;
        margin-top: 32px;
        margin-bottom: 32px;
        position: relative;
        width: 100%;
        text-align: left;
        @media screen and (max-width: 710px) {
            font-size: 1.4rem;
        }
    }

    .company-info-primary,
    .company-mailing-info {
        width: 800px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 710px) {
            width: calc(100% - 40px);
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
            width: calc(100% - 5rem);
        }
        .form-text {
            margin-top: 32px;
            width: 100%;
            @media screen and (max-width: 710px) {
                align-self: center;
                & ~ h2 .required_text {
                    display: none;
                }
            }
        }

        & + input[type='submit'] {
            margin: 1rem 1rem 5rem 0;
            font-weight: 600;
            cursor: pointer;
        }

        h2 .required_text {
            position: absolute;
            right: 2%;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
            &:before {
                content: '';
                position: relative;
                display: inline-block;
                width: 8px;
                height: 20px;
                background-color: ${props => props.theme.colors.primaryYellow};
                margin-right: 5px;
                top: 5px;
            }
        }
        small {
            text-transform: capitalize;
            color: #676767;
            font-size: 60%;
            position: absolute;
            margin-top: 56px;
            @media screen and (max-width: 710px) {
                margin-top: -27px;
            }
        }
    }

    .sms__container {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
        }

        .sms__checkbox {
            margin-bottom: 16px;
        }

        .cellphone-prompt {
            color: ${props => props.theme.colors.error};

            @media screen and (min-width: 1024px) {
                margin-left: 8px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .flex-row {
            display: flex;
            flex-direction: row;
        }

        h2 {
            font-size: 30px;
            align-self: flex-start;
        }
    }

    .company-mailing-info {
        margin-bottom: 48px;
        h2 {
            font-size: 24px;
            margin-top: 32px;
            margin-bottom: 32px;
        }

        @media screen and (min-width: 1024px) {
            h2 {
                font-size: 30px;
                text-align: left;
            }
        }
    }
`;

//This will most likely need to be a class component to facilitate the state with or without mailing address
// to display the additional form elements needed to collect that information
function CompanyForm({ onSubmit, submitRef, prevBtnRef, ...props }) {
    const {
        register,
        handleSubmit,
        errors,
        getValues,
        control,
    } = useForm({ defaultValues: { ...props.companyForm } });
    const [isMailingDifferent, setIsMailingDifferent] = useState(
        props.mailingAddressIsDifferent
    );
    const [smsOptInClicked, setSmsOptInClicked] = useState(
        props.companyForm.SMS_Opt_In__c
    );
    const [cellPhone, setCellPhone] = useState([]);

    useEffect(() => {
        setIsMailingDifferent(props.companyForm.mailingAddressIsDifferent);
    }, [props.companyForm.mailingAddressIsDifferent]);

    return (
        <StyledCompanyForm onSubmit={handleSubmit(onSubmit)}>
            <div className="company-info-primary">
                <p className="form-text">
                    Gain control of your fleet today by completing the following
                    application and benefit from online account management,
                    security prompt controls and potential savings.
                </p>
                <h2>
                    Company Information
                    <strong className="required_text">Required</strong>
                </h2>
                <HookFormInput
                    name="company"
                    register={register}
                    hasError={errors.company && true}
                    errorText={errors.company && errors.company.message}
                    label="Legal Name of Business"
                    required={true}
                    value={props.company && props.company}
                    defaultValue={props.companyForm.company}
                ></HookFormInput>
                <HookFormInput
                    name="address"
                    register={register}
                    hasError={errors.address && true}
                    errorText={errors.address && errors.address.message}
                    label="Physical Business Address"
                    required={true}
                    value={props.address && props.address}
                    defaultValue={props.companyForm.address}
                ></HookFormInput>
                <div className="flex-row">
                    <HookFormInput
                        name="city"
                        register={register}
                        hasError={errors.city && true}
                        errorText={errors.city && errors.city.message}
                        label="City"
                        required={true}
                        value={props.city && props.city}
                        defaultValue={props.companyForm.city}
                    ></HookFormInput>
                    <HookFormSelect
                        name="state"
                        register={register}
                        hasError={errors.state && true}
                        errorText={errors.state && errors.state.message}
                        label="State"
                        required={true}
                        defaultValue={
                            props.companyForm.state && props.companyForm.state
                        }
                        control={control}
                        options={StateOptions}
                    ></HookFormSelect>
                    <HookFormInput
                        name="zipCode"
                        register={register}
                        hasError={errors.zipCode && true}
                        errorText={errors.zipCode && errors.zipCode.message}
                        label="Zip Code"
                        required={true}
                        value={props.zipCode && props.zipCode}
                        validate={value => {
                            if (value.length < 5 || value.length > 6) {
                                return 'Enter a valid zip code';
                            }
                        }}
                        defaultValue={props.companyForm.zipCode}
                        maskType="Number"
                    ></HookFormInput>
                </div>
                <HookFormCheckbox
                    name="mailingAddressIsDifferent"
                    register={register}
                    label="I have different mailing address"
                    checked={
                        props.companyForm.mailingAddressIsDifferent
                            ? true
                            : false
                    }
                    onChange={e => {
                        setIsMailingDifferent(e.target.checked);
                    }}
                    defaultChecked={props.companyForm.mailingAddressIsDifferent}
                ></HookFormCheckbox>
            </div>
            {isMailingDifferent && (
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{
                        x: { type: 'spring', stiffness: 300, damping: 200 },
                        opacity: { duration: 0.2 },
                    }}
                    className="company-mailing-info"
                >
                    <h2>MAILING ADDRESS OR PO BOX</h2>

                    <HookFormInput
                        name="mailingAddress"
                        register={register}
                        hasError={errors.mailingAddress && true}
                        errorText={
                            errors.mailingAddress &&
                            errors.mailingAddress.message
                        }
                        label="Street Address"
                        required={isMailingDifferent}
                        value={props.mailingAddress && props.mailingAddress}
                        defaultValue={props.companyForm.mailingAddress}
                    ></HookFormInput>
                    <div className="flex-row">
                        <HookFormInput
                            name="mailingCity"
                            register={register}
                            hasError={errors.mailingCity && true}
                            errorText={
                                errors.mailingCity && errors.mailingCity.message
                            }
                            label="City"
                            required={isMailingDifferent}
                            value={props.mailingCity && props.mailingCity}
                            defaultValue={props.companyForm.mailingCity}
                        ></HookFormInput>
                        <HookFormSelect
                            name="mailingState"
                            register={register}
                            hasError={errors.mailingState && true}
                            errorText={
                                errors.mailingState &&
                                errors.mailingState.message
                            }
                            label="State"
                            required={isMailingDifferent}
                            defaultValue={
                                props.mailingState && props.mailingState
                            }
                            options={StateOptions}
                            control={control}
                        ></HookFormSelect>
                        <HookFormInput
                            name="mailingZip"
                            register={register}
                            hasError={errors.mailingZip && true}
                            errorText={
                                errors.mailingZip && errors.mailingZip.message
                            }
                            label="Zip Code"
                            required={isMailingDifferent}
                            value={props.mailingZip && props.mailingZip}
                            defaultValue={props.companyForm.mailingZip}
                            validate={value => {
                                if (value.length < 5 || value.length > 6) {
                                    return 'Enter a valid zip code';
                                }
                            }}
                            maskType="Number"
                        ></HookFormInput>
                    </div>
                </motion.div>
            )}
            <div className="company-info-primary">
                <div className="flex-row">
                    <HookFormInput
                        placeholder="###-###-####"
                        pattern={/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/}
                        name="mailingPhone"
                        register={register}
                        hasError={errors.mailingPhone && true}
                        errorText={
                            errors.mailingPhone && errors.mailingPhone.message
                        }
                        label="Phone"
                        value={props.mailingPhone && props.mailingPhone}
                        maskType="Phone"
                        required={true}
                        defaultValue={props.companyForm.mailingPhone}
                    ></HookFormInput>
                    <HookFormInput
                        placeholder="###-###-####"
                        pattern={/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/}
                        name="mailingCellPhone"
                        register={register}
                        hasError={errors.mailingCellPhone && true}
                        errorText={
                            errors.mailingCellPhone &&
                            errors.mailingCellPhone.message
                        }
                        label="Cell Phone"
                        value={props.mailingCellPhone && props.mailingCellPhone}
                        maskType="Phone"
                        defaultValue={props.companyForm.mailingCellPhone}
                        required={smsOptInClicked}
                        validate={value => {
                            if (getValues()['SMS_Opt_In__c'] && !value) {
                                return '';
                            }
                        }}
                        onChange={e => {
                            setCellPhone(e.target.value);
                        }}
                    ></HookFormInput>
                </div>
                <div className="flex-row sms__container">
                    <HookFormCheckbox
                        className="sms__checkbox"
                        name="SMS_Opt_In__c"
                        register={register}
                        label="I want to receive SMS texts on the status of my credit application."
                        onChange={e => {
                            setSmsOptInClicked(e.target.checked);
                            setCellPhone(
                                document.getElementById('mailingCellPhone')
                                    .value
                            );
                        }}
                        checked={props.companyForm.SMS_Opt_In__c ? true : false}
                        defaultChecked={props.companyForm.SMS_Opt_In__c}
                    ></HookFormCheckbox>
                    {smsOptInClicked && cellPhone.length < 12 ? (
                        <span className="cellphone-prompt">
                            Please fill out the cell phone field above
                        </span>
                    ) : null}
                </div>
                <div className="flex-row">
                    <HookFormInput
                        name="mailingFax"
                        register={register}
                        placeholder="(###)###-####"
                        pattern={/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/}
                        hasError={errors.mailingFax && true}
                        errorText={
                            errors.mailingFax && errors.mailingFax.message
                        }
                        label="Fax"
                        value={props.mailingFax && props.mailingFax}
                        maskType="Fax"
                        defaultValue={props.companyForm.mailingFax}
                    ></HookFormInput>
                </div>
                <div className="flex-row">
                    <HookFormInput
                        name="mailingEmail"
                        placeholder="YourEmail@place.com"
                        register={register}
                        hasError={errors.mailingEmail && true}
                        errorText={
                            errors.mailingEmail && errors.mailingEmail.message
                        }
                        pattern={/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/}
                        label="Email"
                        required={true}
                        value={props.mailingEmail && props.mailingEmail}
                        defaultValue={props.companyForm.mailingEmail}
                    ></HookFormInput>
                    <small>
                        * We will use this email for any followup communication
                    </small>
                    <HookFormInput
                        name="mailingConfirmEmail"
                        placeholder="YourEmail@place.com"
                        register={register}
                        hasError={errors.mailingConfirmEmail && true}
                        errorText={
                            errors.mailingConfirmEmail &&
                            errors.mailingConfirmEmail.message
                        }
                        pattern={/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/}
                        label="Confirm Email"
                        required={true}
                        value={
                            props.mailingConfirmEmail &&
                            props.mailingConfirmEmail
                        }
                        defaultValue={props.companyForm.mailingConfirmEmail}
                        validate={value => {
                            if (value !== getValues()['mailingEmail']) {
                                return "Your Email Doesn't Match";
                            }
                        }}
                    ></HookFormInput>
                </div>
                <div className="flex-row">
                    <HookFormSelect
                        name="isTaxExempt"
                        register={register}
                        hasError={errors.isTaxExempt && true}
                        errorText={
                            errors.isTaxExempt && errors.isTaxExempt.message
                        }
                        label="Tax Exempt?"
                        required={true}
                        defaultValue={
                            props.companyForm.isTaxExempt &&
                            props.companyForm.isTaxExempt
                        }
                        control={control}
                        options={TaxExempt}
                    ></HookFormSelect>
                    <HookFormInput
                        name="companyFEIN"
                        register={register}
                        placeholder="##-#######"
                        hasError={errors.companyFEIN && true}
                        errorText={
                            errors.companyFEIN && errors.companyFEIN.message
                        }
                        label="Federal ID or BN #"
                        required={true}
                        minLength={4}
                        maxLength={10}
                        value={props.companyFEIN && props.companyFEIN}
                        defaultValue={props.companyForm.companyFEIN}
                        maskType="FederalId"
                    ></HookFormInput>
                </div>
                <div className="flex-row">
                    <HookFormInput
                        name="DOTNumber"
                        type="number"
                        register={register}
                        hasError={errors.DOTNumber && true}
                        errorText={errors.DOTNumber && errors.DOTNumber.message}
                        label="DOT #"
                        value={props.DOTNumber && props.DOTNumber}
                        defaultValue={props.companyForm.DOTNumber}
                    ></HookFormInput>
                    <HookFormInput
                        name="dunBradStreetNumber"
                        register={register}
                        hasError={errors.dunBradStreetNumber && true}
                        errorText={
                            errors.dunBradStreetNumber &&
                            errors.dunBradStreetNumber.message
                        }
                        label="Dun & Bradstreet #"
                        minLength={9}
                        maxLength={9}
                        value={
                            props.dunBradStreetNumber &&
                            props.dunBradStreetNumber
                        }
                        defaultValue={props.companyForm.dunBradStreetNumber}
                    ></HookFormInput>
                </div>

                <CreditSubmitButton
                    text="NEXT"
                    buttonref={submitRef}
                ></CreditSubmitButton>
            </div>
        </StyledCompanyForm>
    );
}

CompanyForm.propTypes = {};

export default CompanyForm;
