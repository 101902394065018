import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import HookFormInput from '../UI/HookFormInput';
import HookFormSelect from '../UI/HookFormSelect';
import styled from 'styled-components';
import HookFormCheckbox from '../UI/HookFormCheckbox';
import HookFormCheckboxGroup from '../UI/HookFormCheckboxGroup';
import CreditSubmitButton from '../UI/CreditSubmitButton';

const StyledFleetInfoForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    h2 {
        font-size: 24px;
        margin-top: 32px;
        margin-bottom: 32px;
        position: relative;
        width: 100%;
        text-align: left;

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
        }
    }

    .fleet-info-primary {
        width: 800px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 768px) {
            width: calc(100% - 40px);
        }

        @media screen and (min-width: 768px) and (max-width: 1024px) {
            width: calc(100% - 5rem);
        }

        .form-text {
            margin-top: 32px;
            width: 100%;
            @media screen and (max-width: 768px) {
                align-self: center;
                & ~ h2 .required_text {
                    display: none;
                }
            }
        }

        & + input[type='submit'] {
            margin: 1em 1em 5em 0;
            font-weight: 600;
            cursor: pointer;
        }

        h2 .required_text {
            position: absolute;
            right: 2%;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
            &:before {
                content: '';
                position: relative;
                display: inline-block;
                width: 8px;
                height: 20px;
                background-color: ${props => props.theme.colors.primaryYellow};
                margin-right: 5px;
                top: 5px;
            }

            @media screen and (max-width: 768px) {
                display: none;
            }
        }

        .checkbox-groups {
            width: 50%;
            padding-right: 40px;
        }

        .group-description {
            font-size: 1rem;
            line-height: 1.25rem;
        }

        .instructions {
            margin-top: 10px;
            font-size: 0.75rem;
            line-height: 0.875rem;
        }

        .error {
            color: ${props => props.theme.colors.error};
            font-weight: bold;
        }

        .checkboxes {
            margin-top: 14px;

            & > div {
                margin-bottom: 14px;
            }
        }

        .btn-group {
            display: flex;
            justify-content: flex-end;

            @media screen and (max-width: 768px) {
                justify-content: space-between;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .flex-row {
            display: flex;
            flex-direction: row;
        }

        h2 {
            font-size: 30px;
            align-self: flex-start;
        }
    }

    label[for='fleetWeeklyUsage'] {
        top: 4px !important;
    }
`;
function FleetInfoForm({ onSubmit, submitRef, prevBtnRef, ...props }) {
    const {
        register,
        handleSubmit,
        errors,
        unregister,
        getValues,
        control,
    } = useForm({
        defaultValues: { ...props.fleetForm },
    });
    const [
        isFleetContactSameAsOfficer,
        setIsFleetContactSameAsOfficer,
    ] = useState(props.sameAsOfficerOrOwner);
    const [autoDiesel, setAutoDiesel] = useState(
        props.fleetForm.Auto_Diesel__c
    );
    const [commercialDiesel, setCommercialDiesel] = useState(
        props.fleetForm.Commerical_Diesel__c
    );
    const [gasoline, setGasoline] = useState(props.fleetForm.Gasoline__c);
    const [freightBillFactoring, setFreightBillFactoring] = useState(
        props.fleetForm.Freight_Bill_Factoring__c
    );
    const [
        truckCareMaintenancePackages,
        setTruckCareMaintenancePackages,
    ] = useState(props.fleetForm.Truck_Maintenance_Packages__c);
    const [errFuelBuying, setErrFuelBuying] = useState(['']);
    const [fleetContactFirstName, setIsFleetContactFirstName] = useState();
    const [fleetContactLastName, setIsFleetContactLastName] = useState();
    const [fleetContactTitle, setIsFleetContactTitle] = useState();
    const [fleetContactPhone, setIsFleetContactPhone] = useState();
    const [fleetContactCellPhone, setIsFleetContactCellPhone] = useState();
    const [fleetContactFax, setIsFleetContactFax] = useState();
    const [fleetContactEmail, setIsFleetContactEmail] = useState();
    const oldValueRef = useRef(0);

    const validateCheckboxes = () => {
        const values = getValues();
        if (
            !values.Auto_Diesel__c &&
            !values.Commercial_Diesel__c &&
            !values.Gasoline__c
        ) {
            setErrFuelBuying('Must select one');
            return false;
        } else {
            setErrFuelBuying('');
            return true;
        }
    };
    return (
        <StyledFleetInfoForm onSubmit={handleSubmit(onSubmit)}>
            <div className="fleet-info-primary">
                <p className="form-text">
                    Please include your fleet information below
                </p>
                <h2>
                    Fleet and Vehicle Information
                    <strong className="required_text">Required</strong>
                </h2>
                <HookFormCheckbox
                    name="sameAsOfficerOrOwner"
                    register={register}
                    label="Same as Officer or Owner"
                    checked={
                        props.fleetForm.sameAsOfficerOrOwner ? true : false
                    }
                    onChange={e => {
                        setIsFleetContactSameAsOfficer(e.target.checked);
                        setIsFleetContactFirstName(
                            e.target.checked
                                ? props.peopleForm.OfficerFirstName
                                : ''
                        );
                        setIsFleetContactLastName(
                            e.target.checked
                                ? props.peopleForm.OfficerLastName
                                : ''
                        );
                        setIsFleetContactTitle(
                            e.target.checked
                                ? props.peopleForm.OfficerTitle
                                : ''
                        );
                        setIsFleetContactPhone(
                            e.target.checked
                                ? props.companyForm.mailingPhone
                                : ''
                        );
                        setIsFleetContactCellPhone(
                            e.target.checked
                                ? props.companyForm.mailingCellPhone
                                : ''
                        );
                        setIsFleetContactFax(
                            e.target.checked ? props.companyForm.mailingFax : ''
                        );
                        setIsFleetContactEmail(
                            e.target.checked
                                ? props.companyForm.mailingEmail
                                : ''
                        );
                    }}
                    defaultValue={props.fleetForm.sameAsOfficerOrOwner}
                ></HookFormCheckbox>
                <div className="flex-row">
                    <HookFormInput
                        name="fleetContactFirstName"
                        register={register}
                        hasError={errors.fleetContactFirstName && true}
                        errorText={
                            errors.fleetContactFirstName &&
                            errors.fleetContactFirstName.message
                        }
                        label="Authorized Fleet Contact First Name"
                        required={false}
                        onChange={e => {
                            setIsFleetContactFirstName(e.target.value);
                        }}
                        value={fleetContactFirstName && fleetContactFirstName}
                        defaultValue={props.fleetForm.fleetContactFirstName}
                    ></HookFormInput>
                    <HookFormInput
                        name="fleetContactLastName"
                        register={register}
                        hasError={errors.fleetContactLastName && true}
                        errorText={
                            errors.fleetContactLastName &&
                            errors.fleetContactLastName.message
                        }
                        label="Authorized Fleet Contact Last Name"
                        required={false}
                        onChange={e => {
                            setIsFleetContactLastName(e.target.value);
                        }}
                        value={fleetContactLastName && fleetContactLastName}
                        defaultValue={props.fleetForm.fleetContactLastName}
                    ></HookFormInput>
                </div>
                <div className="flex-row">
                    <HookFormInput
                        name="fleetContactTitle"
                        register={register}
                        hasError={errors.fleetContactTitle && true}
                        errorText={
                            errors.fleetContactTitle &&
                            errors.fleetContactTitle.message
                        }
                        label="Title"
                        required={false}
                        onChange={e => {
                            setIsFleetContactTitle(e.target.value);
                        }}
                        value={fleetContactTitle && fleetContactTitle}
                        defaultValue={props.fleetForm.fleetContactTitle}
                    ></HookFormInput>
                    <HookFormInput
                        name="fleetContactPhone"
                        type="tel"
                        register={register}
                        placeholder="###-###-####"
                        pattern={/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/}
                        hasError={errors.fleetContactPhone && true}
                        errorText={
                            errors.fleetContactPhone &&
                            errors.fleetContactPhone.message
                        }
                        label="Phone"
                        required={false}
                        maskType="Phone"
                        onChange={e => {
                            maskPhoneFaxOnChange(e);
                            setIsFleetContactPhone(e.target.value);
                        }}
                        value={fleetContactPhone && fleetContactPhone}
                        defaultValue={props.fleetForm.fleetContactPhone}
                    ></HookFormInput>
                </div>
                <div className="flex-row">
                    <HookFormInput
                        type="tel"
                        name="fleetContactCellPhone"
                        placeholder="###-###-####"
                        pattern={/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/}
                        register={register}
                        hasError={errors.fleetContactCellPhone && true}
                        errorText={
                            errors.fleetContactCellPhone &&
                            errors.fleetContactCellPhone.message
                        }
                        label="Cell Phone"
                        required={false}
                        onChange={e => {
                            maskPhoneFaxOnChange(e);
                            setIsFleetContactCellPhone(e.target.value);
                        }}
                        value={fleetContactCellPhone && fleetContactCellPhone}
                        defaultValue={props.fleetForm.fleetContactCellPhone}
                        maskType="Phone"
                    ></HookFormInput>
                    <HookFormInput
                        name="fleetContactFax"
                        placeholder="(###)###-####"
                        pattern={/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/}
                        register={register}
                        hasError={errors.fleetContactFax && true}
                        errorText={
                            errors.fleetContactFax &&
                            errors.fleetContactFax.message
                        }
                        label="Fax"
                        required={false}
                        onChange={e => {
                            maskPhoneFaxOnChange(e);
                            setIsFleetContactFax(e.target.value);
                        }}
                        value={fleetContactFax && fleetContactFax}
                        defaultValue={props.fleetForm.fleetContactFax}
                        maskType="Fax"
                    ></HookFormInput>
                </div>
                <div className="flex-row">
                    <HookFormInput
                        name="fleetContactEmail"
                        placeholder="YourEmail@place.com"
                        register={register}
                        hasError={errors.fleetContactEmail && true}
                        errorText={
                            errors.fleetContactEmail &&
                            errors.fleetContactEmail.message
                        }
                        label="Email"
                        pattern={/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/}
                        required={false}
                        onChange={e => {
                            setIsFleetContactEmail(e.target.value);
                        }}
                        value={fleetContactEmail && fleetContactEmail}
                        defaultValue={props.fleetForm.fleetContactEmail}
                    ></HookFormInput>
                    <HookFormSelect
                        name="fleetNumberOfVehicles"
                        register={register}
                        hasError={errors.fleetNumberOfVehicles && true}
                        errorText={
                            errors.fleetNumberOfVehicles &&
                            errors.fleetNumberOfVehicles.message
                        }
                        label="Number of Vehicles"
                        required={true}
                        options={IncrementNumbers(30)}
                        control={control}
                        defaultValue={props.fleetForm.fleetNumberOfVehicles}
                    ></HookFormSelect>
                </div>
                <div className="flex-row">
                    <HookFormInput
                        name="fleetNumberOfCardsRequested"
                        type="number"
                        register={register}
                        hasError={errors.fleetNumberOfCardsRequested && true}
                        errorText={
                            errors.fleetNumberOfCardsRequested &&
                            errors.fleetNumberOfCardsRequested.message
                        }
                        label="Number of Cards Requested"
                        required={true}
                        value={
                            props.fleetNumberOfCardsRequested &&
                            props.fleetNumberOfCardsRequested
                        }
                        defaultValue={
                            props.fleetForm.fleetNumberOfCardsRequested
                        }
                    ></HookFormInput>
                    <HookFormInput
                        name="fleetWeeklyUsage"
                        type="number"
                        register={register}
                        hasError={errors.fleetWeeklyUsage && true}
                        errorText={
                            errors.fleetWeeklyUsage &&
                            errors.fleetWeeklyUsage.message
                        }
                        label="Expected Weekly Usage at Pilot and Flying J (in Dollars $)"
                        required={true}
                        value={props.fleetWeeklyUsage && props.fleetWeeklyUsage}
                        defaultValue={props.fleetForm.fleetWeeklyUsage}
                    ></HookFormInput>
                </div>
                <div className="flex-row">
                    <div className="fuel-types checkbox-groups">
                        <p className="group-description">
                            Please select the type of fuel you will be buying
                            with this card.*
                        </p>
                        <span className="instructions">
                            Check all that apply{' '}
                            <span className="error">{errFuelBuying}</span>
                        </span>
                        <div className="checkboxes">
                            <HookFormCheckboxGroup
                                name="Commercial_Diesel__c"
                                register={register({
                                    validate: validateCheckboxes,
                                })}
                                label="Commercial Diesel"
                                checked={
                                    props.fleetForm.Commercial_Diesel__c
                                        ? true
                                        : false
                                }
                                onChange={e => {
                                    setCommercialDiesel(e.target.checked);
                                }}
                                defaultChecked={
                                    props.fleetForm.Commercial_Diesel__c
                                }
                            ></HookFormCheckboxGroup>
                            <HookFormCheckboxGroup
                                name="Gasoline__c"
                                register={register({
                                    validate: validateCheckboxes,
                                })}
                                label="Gasoline"
                                checked={
                                    props.fleetForm.Gasoline__c ? true : false
                                }
                                onChange={e => {
                                    setGasoline(e.target.checked);
                                }}
                                defaultChecked={props.fleetForm.Gasoline__c}
                            ></HookFormCheckboxGroup>
                            <HookFormCheckboxGroup
                                name="Auto_Diesel__c"
                                register={register({
                                    validate: validateCheckboxes,
                                })}
                                label="Auto Diesel"
                                checked={
                                    props.fleetForm.Auto_Diesel__c
                                        ? true
                                        : false
                                }
                                onChange={e => {
                                    setAutoDiesel(e.target.checked);
                                }}
                                defaultChecked={props.fleetForm.Auto_Diesel__c}
                            ></HookFormCheckboxGroup>
                        </div>
                    </div>
                    <div className="fleet-solutions checkbox-groups">
                        <p className="group-description">
                            Are you intersetd in any other fleet solutions?
                        </p>
                        <span className="instructions">
                            Check all that apply
                        </span>
                        <div className="checkboxes">
                            <HookFormCheckbox
                                name="Freight_Bill_Factoring__c"
                                register={register}
                                label="Factoring Services"
                                required={false}
                                checked={
                                    props.fleetForm.Freight_Bill_Factoring__c
                                        ? true
                                        : false
                                }
                                onChange={e => {
                                    setFreightBillFactoring(e.target.checked);
                                }}
                                defaultChecked={
                                    props.fleetForm.Freight_Bill_Factoring__c
                                }
                            ></HookFormCheckbox>
                            <HookFormCheckbox
                                name="Truck_Care_Maintenance_Packages__c"
                                register={register}
                                label="Truck Care Maintencance Packages"
                                required={false}
                                checked={
                                    props.fleetForm
                                        .Truck_Care_Maintenance_Packages__c
                                        ? true
                                        : false
                                }
                                onChange={e => {
                                    setTruckCareMaintenancePackages(
                                        e.target.checked
                                    );
                                }}
                                defaultChecked={
                                    props.fleetForm
                                        .Truck_Care_Maintenance_Packages__c
                                }
                            ></HookFormCheckbox>
                        </div>
                    </div>
                </div>
                <div className="btn-group">
                    <CreditSubmitButton
                        text="PREVIOUS"
                        buttonType="button"
                        buttonref={prevBtnRef}
                        onPrevClick={() => props.onPrevious(getValues())}
                    ></CreditSubmitButton>
                    <CreditSubmitButton
                        text="NEXT"
                        buttonref={submitRef}
                    ></CreditSubmitButton>
                </div>
            </div>
        </StyledFleetInfoForm>
    );
    //Provides increment numbers for dropdown
    function IncrementNumbers(val) {
        var tempArr = [];
        for (var i = 1; i <= val; i++) {
            tempArr.push({
                value: i,
                label: i,
            });
        }
        return tempArr;
    }

    function maskPhoneFaxOnChange(e) {
        var x = e.target.value
            .replace(/\D/g, '')
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        let caret1 = e.target.selectionStart;
        e.target.value = !x[2]
            ? x[1]
            : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : ''); 
        const perviousCount = oldValueRef.current.length;
        if (
            (caret1 === 4 && perviousCount < e.target.value.length) ||
            (caret1 === 8 && perviousCount < e.target.value.length)
          ) {
            caret1 = caret1 + 1;
          }
        oldValueRef.current = e.target.value;
        e.target.setSelectionRange(caret1, caret1);
    }
}

FleetInfoForm.propTypes = {};

export default FleetInfoForm;
