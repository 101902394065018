import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import HookFormInput from '../UI/HookFormInput';
import styled from 'styled-components';
import HookFormCheckbox from '../UI/HookFormCheckbox';
import CreditSubmitButton from '../UI/CreditSubmitButton';

const StyledPeopleForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    h2 {
        font-size: 24px;
        margin-top: 32px;
        margin-bottom: 32px;
        position: relative;
        width: 100%;
        text-align: left;
        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
        }
    }
    a {
        font-family: ${props => props.theme.fonts[1]}, sans-serif;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        color: #cf0a2c;
        text-align: left;
        cursor: pointer;
        font-size: 14px;
        display: contents;
    }
    .people-info-primary {
        width: 800px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 768px) {
            width: calc(100% - 40px);
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
            width: calc(100% - 5rem);
        }
        .btn-group {
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width: 768px) {
                justify-content: space-between;
            }
        }
        .form-text {
            margin-top: 32px;
            width: 100%;
            @media screen and (max-width: 768px) {
                align-self: center;
                & ~ h2 .required_text {
                    display: none;
                }
            }
        }

        & + input[type='submit'] {
            margin: 1em 1em 5em 0;
            font-weight: 600;
            cursor: pointer;
        }
        h2 .required_text {
            position: absolute;
            right: 2%;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
            &:before {
                content: '';
                position: relative;
                display: inline-block;
                width: 8px;
                height: 20px;
                background-color: ${props => props.theme.colors.primaryYellow};
                margin-right: 5px;
                top: 5px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .flex-row {
            display: flex;
            flex-direction: row;
        }

        h2 {
            font-size: 30px;
            align-self: flex-start;
        }
    }
`;

//This will most likely need to be a class component to facilitate the state with or without mailing address
// to display the additional form elements needed to collect that information
function PeopleForm({ onSubmit, prevBtnRef, submitRef, ...props }) {
    const { register, handleSubmit, errors, getValues } = useForm({
        defaultValues: { ...props.peopleForm },
    });

    const [isAddAnotherOfficer, setIsAddAnotherOfficer] = useState(
        props.peopleForm.addAnotherOfficer
    );
    const [isAddressSameAsOfficer, setIsAddressSameAsOfficer] = useState(
        props.addressSameAsOfficerOrOwner
    );
    const [BillingContactFirstName, setBillingContactFirstName] = useState();
    const [BillingContactLastName, setBillingContactLastName] = useState();
    const [BillingContactTitle, setBillingContactTitle] = useState();
    const [
        BillingContactPhoneNumber,
        setBillingContactPhoneNumber,
    ] = useState();
    const [BillingContactFaxNumber, setBillingContactFaxNumber] = useState();
    const [BillingContactEmail, setBillingContactEmail] = useState();
    const oldValueRef = useRef(0);

    return (
        <StyledPeopleForm onSubmit={handleSubmit(onSubmit)}>
            <div className="people-info-primary">
                <p className="form-text">
                    Please include your officers and billing contact information
                    below.
                </p>
                <h2>
                    Proprietor, Partners, or Corporate Officers
                    <strong className="required_text">Required</strong>
                </h2>
                <HookFormInput
                    name="OfficerFirstName"
                    register={register}
                    hasError={errors.OfficerFirstName && true}
                    errorText={
                        errors.OfficerFirstName &&
                        errors.OfficerFirstName.message
                    }
                    label="First Name"
                    value={props.OfficerFirstName && props.OfficerFirstName}
                    required={true}
                    defaultValue={props.peopleForm.OfficerFirstName}
                ></HookFormInput>
                <HookFormInput
                    name="OfficerLastName"
                    register={register}
                    hasError={errors.OfficerLastName && true}
                    errorText={
                        errors.OfficerLastName && errors.OfficerLastName.message
                    }
                    label="Last Name"
                    value={props.OfficerLastName && props.OfficerLastName}
                    required={true}
                    defaultValue={props.peopleForm.OfficerLastName}
                ></HookFormInput>
                <HookFormInput
                    name="OfficerTitle"
                    register={register}
                    hasError={errors.OfficerTitle && true}
                    errorText={
                        errors.OfficerTitle && errors.OfficerTitle.message
                    }
                    label="Title"
                    value={props.OfficerTitle && props.OfficerTitle}
                    required={true}
                    defaultValue={props.peopleForm.OfficerTitle}
                ></HookFormInput>
                {!isAddAnotherOfficer ? (
                    <a
                        id="add-another-officer"
                        className=""
                        target=""
                        rel="noreferrer noopener"
                        onClick={() => {
                            setIsAddAnotherOfficer(true);
                            props.peopleForm.addAnotherOfficer = true;
                        }}
                    >
                        +Add Another Officer or Owner
                    </a>
                ) : (
                    <React.Fragment>
                        {/* Adding this field to set addAnotherOfficer flag to people object*/}
                        <input
                            type="hidden"
                            name="addAnotherOfficer"
                            defaultValue={props.peopleForm.addAnotherOfficer}
                            ref={register}
                        />
                        <HookFormInput
                            name="OfficerFirstName1"
                            register={register}
                            hasError={errors.OfficerFirstName1 && true}
                            errorText={
                                errors.OfficerFirstName1 &&
                                errors.OfficerFirstName1.message
                            }
                            label="First Name"
                            value={
                                props.OfficerFirstName1 &&
                                props.OfficerFirstName1
                            }
                            defaultValue={props.peopleForm.OfficerFirstName1}
                        ></HookFormInput>
                        <HookFormInput
                            name="OfficerLastName1"
                            register={register}
                            hasError={errors.OfficerLastName1 && true}
                            errorText={
                                errors.OfficerLastName1 &&
                                errors.OfficerLastName1.message
                            }
                            label="Last Name"
                            value={
                                props.OfficerLastName1 && props.OfficerLastName1
                            }
                            defaultValue={props.peopleForm.OfficerLastName1}
                        ></HookFormInput>
                        <HookFormInput
                            name="OfficerTitle1"
                            register={register}
                            hasError={errors.OfficerTitle1 && true}
                            errorText={
                                errors.OfficerTitle1 &&
                                errors.OfficerTitle1.message
                            }
                            label="Title"
                            value={props.OfficerTitle1 && props.OfficerTitle1}
                            defaultValue={props.peopleForm.OfficerTitle1}
                        ></HookFormInput>
                    </React.Fragment>
                )}
                <h2>Billing Contacts</h2>
                <HookFormCheckbox
                    name="addressSameAsOfficerOrOwner"
                    register={register}
                    label="Same as Officer or Owner"
                    checked={
                        props.peopleForm.addressSameAsOfficerOrOwner
                            ? true
                            : false
                    }
                    onChange={e => {
                        setIsAddressSameAsOfficer(e.target.checked);
                        setBillingContactFirstName(
                            e.target.checked
                                ? getValues()['OfficerFirstName']
                                : ''
                        );
                        setBillingContactLastName(
                            e.target.checked
                                ? getValues()['OfficerLastName']
                                : ''
                        );
                        setBillingContactTitle(
                            e.target.checked ? getValues()['OfficerTitle'] : ''
                        );
                        setBillingContactPhoneNumber(
                            e.target.checked
                                ? props.companyForm.mailingPhone
                                : ''
                        );
                        setBillingContactFaxNumber(
                            e.target.checked ? props.companyForm.mailingFax : ''
                        );
                        setBillingContactEmail(
                            e.target.checked
                                ? props.companyForm.mailingEmail
                                : ''
                        );
                    }}
                    defaultValue={props.peopleForm.addressSameAsOfficerOrOwner}
                ></HookFormCheckbox>
                <HookFormInput
                    name="BillingContactFirstName"
                    register={register}
                    hasError={errors.BillingContactFirstName && true}
                    errorText={
                        errors.BillingContactFirstName &&
                        errors.BillingContactFirstName.message
                    }
                    label="Billing Contact FirstName"
                    value={BillingContactFirstName && BillingContactFirstName}
                    onChange={e => {
                        setBillingContactFirstName(e.target.value);
                    }}
                    defaultValue={props.peopleForm.BillingContactFirstName}
                ></HookFormInput>
                <HookFormInput
                    name="BillingContactLastName"
                    register={register}
                    hasError={errors.BillingContactLastName && true}
                    errorText={
                        errors.BillingContactLastName &&
                        errors.BillingContactLastName.message
                    }
                    label="Billing Contact LastName"
                    value={BillingContactLastName && BillingContactLastName}
                    onChange={e => {
                        setBillingContactLastName(e.target.value);
                    }}
                    defaultValue={props.peopleForm.BillingContactLastName}
                ></HookFormInput>
                <HookFormInput
                    name="BillingContactTitle"
                    register={register}
                    hasError={errors.BillingContactTitle && true}
                    errorText={
                        errors.BillingContactTitle &&
                        errors.BillingContactTitle.message
                    }
                    label="Title"
                    value={BillingContactTitle && BillingContactTitle}
                    onChange={e => {
                        setBillingContactTitle(e.target.value);
                    }}
                    defaultValue={props.peopleForm.BillingContactTitle}
                ></HookFormInput>
                <HookFormInput
                    placeholder="###-###-####"
                    pattern={/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/}
                    name="BillingContactPhoneNumber"
                    register={register}
                    hasError={errors.BillingContactPhoneNumber && true}
                    errorText={
                        errors.BillingContactPhoneNumber &&
                        errors.BillingContactPhoneNumber.message
                    }
                    label="Phone"
                    value={
                        BillingContactPhoneNumber && BillingContactPhoneNumber
                    }
                    maskType="Phone"
                    onChange={e => {
                        maskPhoneFaxOnChange(e);
                        setBillingContactPhoneNumber(e.target.value);
                    }}
                    defaultValue={props.peopleForm.BillingContactPhoneNumber}
                ></HookFormInput>
                <HookFormInput
                    name="BillingContactFaxNumber"
                    register={register}
                    placeholder="(###)###-####"
                    pattern={/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/}
                    hasError={errors.BillingContactFaxNumber && true}
                    errorText={
                        errors.BillingContactFaxNumber &&
                        errors.BillingContactFaxNumber.message
                    }
                    label="Fax"
                    value={BillingContactFaxNumber && BillingContactFaxNumber}
                    maskType="Fax"
                    onChange={e => {
                        maskPhoneFaxOnChange(e);
                        setBillingContactFaxNumber(e.target.value);
                    }}
                    defaultValue={props.peopleForm.BillingContactFaxNumber}
                ></HookFormInput>
                <HookFormInput
                    name="BillingContactEmail"
                    placeholder="YourEmail@place.com"
                    register={register}
                    hasError={errors.BillingContactEmail && true}
                    errorText={
                        errors.BillingContactEmail &&
                        errors.BillingContactEmail.message
                    }
                    label="Email"
                    pattern={/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/}
                    value={BillingContactEmail && BillingContactEmail}
                    onChange={e => {
                        setBillingContactEmail(e.target.value);
                    }}
                    defaultValue={props.peopleForm.BillingContactEmail}
                ></HookFormInput>
                <div className="btn-group">
                    <CreditSubmitButton
                        text="PREVIOUS"
                        buttonType="button"
                        buttonref={prevBtnRef}
                        onPrevClick={() => props.onPrevious(getValues())}
                    ></CreditSubmitButton>
                    <CreditSubmitButton
                        text="NEXT"
                        buttonref={submitRef}
                    ></CreditSubmitButton>
                </div>
            </div>
        </StyledPeopleForm>
    );
    function maskPhoneFaxOnChange(e) {
        var x = e.target.value
            .replace(/\D/g, '')
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        let caret1 = e.target.selectionStart;
        e.target.value = !x[2]
            ? x[1]
            :x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        const perviousCount = oldValueRef.current.length;
        if (
            (caret1 === 4 && perviousCount < e.target.value.length) ||
            (caret1 === 8 && perviousCount < e.target.value.length)
          ) {
            caret1 = caret1 + 1;
          }
        oldValueRef.current = e.target.value;
        e.target.setSelectionRange(caret1, caret1);
    }
}
PeopleForm.propTypes = {};

export default PeopleForm;
