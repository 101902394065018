import React, { Component, createRef } from 'react';
import { withSize } from 'react-sizeme';
import { getAuthenticated, getContentFromSquidex } from '../actions/squidex';
import AdditionalInfoForm from '../components/CreditApp/AdditionalInfo';
import CompanyForm from '../components/CreditApp/CompanyForm';
import DocuSignForm from '../components/CreditApp/DocuSignForm';
import ETFForm from '../components/CreditApp/ETFForm';
import FleetInfoForm from '../components/CreditApp/FleetInfoForm';
import Layout from '../components/Layout';
import PageTitleBanner from '../components/PageTitleBanner';
import PeopleForm from '../components/CreditApp/PeopleForm';
import ProgressBar from '../components/CreditApp/ProgressBar';
import Review from '../components/CreditApp/Review';
import SEO from '../components/SEO';

import CreditAppStyled from '../components/page-styles/creditapplication-styles';

class CreditApplication extends Component {
    constructor(props) {
        super(props);
        //read from localStorage if values exist, set them
        this.nxtBtnRef = createRef();
        this.preBtnRef = createRef();

        this.state = {
            currentStep: 1,
            //store credit app submission data in this component orrrr use react context
            formSteps: Steps.map(s => ({ ...s })),
            // taxExempt: undefined,
            differentMailingAddress: false,
            streetAddress: null,
            fleetProductsList: [],
            fuelCardTermsPDF: '',
            utmParams: {
                campaign: '',
                medium: '',
                source: '',
            },
        };

        this.creditAppData = {
            companyForm: {},
            peopleForm: {},
            eftForm: {},
            fleetForm: {},
            additionaInfoForm: {},
            docusignFormattedData: {},
        };
    }

    partialApplicationEvent = e => {
        if (this.state.currentStep < 7) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'application-stopped',
                stepNumber: this.state.currentStep,
                stepName: Steps[this.state.currentStep - 1].text,
            });
        }
        window.removeEventListener('beforeunload', this.unloadListenerBind);
    };

    componentDidMount() {
        this.setUTMParams();
        let promise = getAuthenticated(
            process.env.SQUIDEX_AUTH_STRING,
            process.env.SQUIDEX_CLIENT_ID,
            process.env.SQUIDEX_CLIENT_SECRET,
            process.env.SQUIDEX_HOST_URL
        );

        promise.then(token => {
            let promise = getContentFromSquidex(
                token,
                process.env.SQUIDEX_HOST_URL,
                'fuel-card-terms'
            );

            promise.then(response =>
                this.setTermsState(
                    `${process.env.SQUIDEX_ASSETS_PATH}${response.data.items[0].data.terms.iv[0]}`
                )
            );
        });

        this.unloadListenerBind = this.partialApplicationEvent.bind(this);
        window.addEventListener('beforeunload', this.unloadListenerBind);

        let incDate = document.getElementById('incorporation-date');
        if (incDate) {
            incDate.readOnly = true;
        }
    }

    setUTMParams() {
        if (localStorage.getItem('utmCampaign') !== null) {
            localStorage.setItem('utmCampaign', '');
            localStorage.setItem('utmMedium', '');
            localStorage.setItem('utmSource', '');
        }

        let queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const utmCampaign = urlParams.get('utm_campaign');
        const utmMedium = urlParams.get('utm_medium');
        const utmSource = urlParams.get('utm_source');

        localStorage.setItem('utmCampaign', utmCampaign);
        localStorage.setItem('utmMedium', utmMedium);
        localStorage.setItem('utmSource', utmSource);

        this.setState(prevState => ({
            utmParams: {
                ...prevState.utmParams,
                campaign: utmCampaign,
                medium: utmMedium,
                source: utmSource,
            },
        }));
    }

    setTermsState(termsPDFURL) {
        this.setState({
            fuelCardTermsPDF: termsPDFURL,
        });
    }

    SetStep = nextStep => {
        const { currentStep } = this.state;
        if (currentStep < nextStep) {
            // when navigating forwards by clicking progressbar tabs
            if (currentStep === nextStep - 1) {
                // checking validation on current form then moving to next form
                this.nxtBtnRef.current && this.nxtBtnRef.current.click();
            } else {
                // checking validation on current form then moving to target form
                let clickTime = nextStep - currentStep;
                for (let i = clickTime; i >= 0; i--) {
                    // checking all form validations between current and target forms
                    setTimeout(() => {
                        this.nxtBtnRef.current &&
                            this.nxtBtnRef.current.click();
                    }, 3);
                }
            }
        } else if (currentStep !== nextStep) {
            // when navigating backwards by clicking progressbar tabs
            this.preBtnRef.current && this.preBtnRef.current.click();
            this.setState({ currentStep: nextStep });
        }
    };

    // Performs common switching tab functionality
    switchTab = (newTabID, updatedFormSteps) => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        const { currentStep } = this.state;
        updatedFormSteps[currentStep - 1].isComplete = true;
        updatedFormSteps[currentStep - 1].isActive = false;
        updatedFormSteps[newTabID - 1].isActive = true;
        updatedFormSteps[newTabID - 1].isValidated = false; // set next form validation to false to prevent bypass through tabs
        this.setState({ formSteps: updatedFormSteps, currentStep: newTabID });
    };

    NextStep = data => {
        // Get step name and number for submission to GTM datalayer
        var stepName = '';
        var currentStep = this.state.currentStep;
        Steps.forEach(function(step) {
            if (step.id === currentStep) {
                stepName = step.text;
            }
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'application-process',
            stepNumber: this.state.currentStep + 1,
            stepName: stepName,
        });

        let updateStep = this.state.formSteps;
        updateStep[this.state.currentStep - 1].isValidated = true;
        this.InternalSaveAppData(this.state.currentStep, data);
        this.switchTab(this.state.currentStep + 1, updateStep);

        //if Succeeds, set state to new set of inputs, triggerValidation = false
        //controls which form is displayed
    };

    PreviousStep = data => {
        const { currentStep, formSteps } = this.state;
        this.InternalSaveAppData(currentStep, data);
        this.switchTab(currentStep - 1, formSteps);
    };
    EditRespectiveForm = step => {
        this.setState({ currentStep: step });
    };
    SaveProgress = () => {
        //save filled fields to local storage or cookies?
    };

    SubmitCreditApplication = data => {
        const { formSteps } = this.state;
        let updateStep = formSteps;
        updateStep[this.state.currentStep - 1].isValidated = true;
        //compile form data from state to create submission object and send to docusign?
        this.InternalSaveAppData(this.state.currentStep, data);
        this.switchTab(this.state.currentStep + 1, updateStep);
    };
    //Serialize data for docusign param
    SerializeDataForDocusign = data => {
        if (!data) {
            data = this.creditAppData.docusignFormattedData;
        }

        let str = '';
        for (var key in data) {
            if (str !== '') {
                str += '&';
            }
            if (key === 'Officers') {
                for (var index in data['Officers']) {
                    for (var officerKey in data['Officers'][index]) {
                        if (str !== '') {
                            if (index !== '0' || officerKey !== 'FirstName') {
                                str += '&';
                            }
                        }
                        str +=
                            'Officers%5B' +
                            index +
                            '%5D.' +
                            officerKey +
                            '=' +
                            encodeURIComponent(
                                data['Officers'][index][officerKey]
                            );
                    }
                }
            } else {
                str += key + '=' + encodeURIComponent(data[key]);
            }
        }
        return str;
    };
    ShowForm = () => {
        const { currentStep } = this.state;

        switch (currentStep) {
            case 1:
                return (
                    <CompanyForm
                        onPrevious={this.PreviousStep}
                        onSubmit={this.NextStep}
                        submitRef={this.nxtBtnRef}
                        prevBtnRef={this.preBtnRef}
                        class="company_form"
                        {...this.creditAppData}
                    ></CompanyForm>
                );

            case 2:
                return (
                    <PeopleForm
                        onPrevious={this.PreviousStep}
                        onSubmit={this.NextStep}
                        prevBtnRef={this.preBtnRef}
                        submitRef={this.nxtBtnRef}
                        class="people-info-form"
                        {...this.creditAppData}
                    ></PeopleForm>
                );
            case 3:
                return (
                    <ETFForm
                        onPrevious={this.PreviousStep}
                        onSubmit={this.NextStep}
                        prevBtnRef={this.preBtnRef}
                        submitRef={this.nxtBtnRef}
                        class="company-form"
                        {...this.creditAppData}
                    ></ETFForm>
                );
            case 4:
                return (
                    <FleetInfoForm
                        onPrevious={this.PreviousStep}
                        onSubmit={this.NextStep}
                        prevBtnRef={this.preBtnRef}
                        submitRef={this.nxtBtnRef}
                        class="fleet-info-form"
                        {...this.creditAppData}
                    ></FleetInfoForm>
                );
            case 5:
                return (
                    <AdditionalInfoForm
                        onPrevious={this.PreviousStep}
                        onSubmit={this.NextStep}
                        prevBtnRef={this.preBtnRef}
                        submitRef={this.nxtBtnRef}
                        class="additional-info-form"
                        {...this.creditAppData}
                    ></AdditionalInfoForm>
                );
            case 6:
                return (
                    <Review
                        onPrevious={this.PreviousStep}
                        onEdit={this.EditRespectiveForm}
                        prevBtnRef={this.preBtnRef}
                        submitRef={this.nxtBtnRef}
                        onSubmit={this.SubmitCreditApplication}
                        submitRef={this.inputRef}                        class="review-form"
                        fuelCardTermsPDF={this.state.fuelCardTermsPDF}
                        {...this.creditAppData}
                        utmParams={this.state.utmParams}
                    ></Review>
                );
            case 7:
                return (
                    <DocuSignForm payload={this.SerializeDataForDocusign()} />
                );
            default:
                return <h2>Error</h2>;
        }
    };

    InternalSaveAppData(currentStep, data) {
        const traker = window?.ga.getAll()[0];
        const GACLIENTID__c = traker?.get('clientId');
        const GATRACKID__c = traker?.get('trackingId');
        const GAUSERID__c = traker?.get('userId') || null;
        const GAData = { GACLIENTID__c, GATRACKID__c, GAUSERID__c };
        switch (currentStep) {
            case 1: {
                this.creditAppData.companyForm = {
                    ...data,
                    ...GAData,
                };
                break;
            }
            case 2: {
                this.creditAppData.peopleForm = { ...data };
                break;
            }
            case 3: {
                this.creditAppData.eftForm = { ...data };
                break;
            }
            case 4: {
                this.creditAppData.fleetForm = { ...data };
                break;
            }
            case 5: {
                this.creditAppData.additionaInfoForm = { ...data };
                break;
            }
            case 6: {
                this.creditAppData.docusignFormattedData = {
                    ...data,
                    ...GAData,
                };
                break;
            }
            default:
                return <h2>Error</h2>;
        }
    }

    render() {
        const { size } = this.props;
        const { currentStep } = this.state;

        return (
            <Layout hideNav={true} needsSolidHeader={true} size={size}>
                <CreditAppStyled id="apply" className="applyForm_holder">
                    <SEO title="Credit Application" />
                    <PageTitleBanner title="Apply" />
                    <ProgressBar
                        options={this.state.formSteps}
                        currentStep={currentStep}
                        onClick={this.SetStep}
                        containerId="applyForm__steps"
                        containerClass="applyForm__progress"
                    ></ProgressBar>
                    {this.ShowForm()}
                </CreditAppStyled>
            </Layout>
        );
    }
}

const Steps = [
    {
        text: 'COMPANY',
        id: 1,
        isComplete: false,
        isActive: true,
        isValidated: false,
    },
    {
        text: 'PEOPLE',
        id: 2,
        isComplete: false,
        isActive: false,
        isValidated: false,
    },
    {
        text: 'EFT INFO',
        id: 3,
        isComplete: false,
        isActive: false,
        isValidated: false,
    },
    {
        text: 'FLEET INFO',
        id: 4,
        isComplete: false,
        isActive: false,
        isValidated: false,
    },
    {
        text: 'ADDITIONAL INFO',
        id: 5,
        isComplete: false,
        isActive: false,
        isValidated: false,
    },
    {
        text: 'REVIEW',
        id: 6,
        isComplete: false,
        isActive: false,
        isValidated: false,
    },
    {
        text: 'SIGN',
        id: 7,
        isComplete: false,
        isActive: false,
        isValidated: false,
    },
];

export default withSize()(CreditApplication);
