import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ProgressBarStyled = styled.div`
    &.applyForm__progress {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        width: 100%;

        @media screen and (max-width: 768px) {
            width: calc(100% - 40px);
            margin-top: 10px;
        }

        & .applyForm__progress__button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-basis: 165px;
            height: 88px;
            background-color: transparent;
            cursor: pointer;

            &,
            &:focus {
                border: 1px solid rgba(0, 0, 0, 0.1);
                outline: none;

                &:not(:last-child) {
                    border-right-width: 0;
                }
            }

            &.active,
            &.complete {
                background-color: ${props => props.theme.colors.primaryGray};

                & > b {
                    background-color: ${props =>
                        props.theme.colors.primaryYellow};
                }

                &:after {
                    color: #fff;
                }
            }

            & > b {
                width: 29px;
                height: 30px;
                border-radius: 2px;
                color: #fff;
                background-color: #b5b5b5;
                font-family: ${props => props.theme.fonts[1]}, sans-serif;
                font-weight: 500;
                font-size: 1.1rem;
                line-height: 1.8rem;
                text-align: center;
                position: relative;
                top: -0.3rem;
                @media screen and (max-width: 1024px) {
                    font-size: 1rem;
                    height: 28px;
                }
            }

            &:after {
                content: attr(data-item-text);
                position: relative;
                font-family: ${props => props.theme.fonts[1]}, sans-serif;
                font-size: 0.7rem;
                text-transform: uppercase;
                color: #b5b5b5;
                letter-spacing: 2px;
                font-weight: 600;
                @media screen and (max-width: 1024px) {
                    font-size: 0.5rem;
                }
            }

            @media screen and (max-width: 768px) {
                &:after {
                    display: none;
                }

                & > b {
                    top: 0;
                }

                & {
                    height: 60px;
                }
            }
        }
    }
`;

//Renders a list of step buttons that will call a function to change the current step
//current step will be used to determine which is active to style appropriately
export default function ProgressBar({
    options,
    onClick,
    currentStep,
    containerId,
    containerClass,
}) {
    return (
        <ProgressBarStyled id={containerId} className={containerClass}>
            {options.map(item => (
                <button
                    className={
                        item.isComplete
                            ? `${containerClass}__button complete`
                            : item.isActive
                            ? `${containerClass}__button active`
                            : `${containerClass}__button`
                    }
                    data-item-text={item.text}
                    key={item.id}
                    onClick={() => onClick(item.id)}
                >
                    <b className={`count`}>{item.id}</b>
                </button>
            ))}
        </ProgressBarStyled>
    );
}

ProgressBar.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            id: PropTypes.number,
        })
    ).isRequired,
    onClick: PropTypes.func.isRequired,
    currentStep: PropTypes.number.isRequired,
};
