import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import HookFormInput from '../UI/HookFormInput';
import HookFormSelect from '../UI/HookFormSelect';
import HookFormCheckbox from '../UI/HookFormCheckbox';
import styled from 'styled-components';
import CreditSubmitButton from '../UI/CreditSubmitButton';
import { StateOptions } from '../Constants';
const StyledEFTForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    h2 {
        font-size: 24px;
        margin-top: 32px;
        margin-bottom: 32px;
        position: relative;
        width: 100%;
        text-align: left;
        @media screen and (max-width: 710px) {
            font-size: 1.4rem;
        }
    }

    .etf-info-primary {
        width: 800px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 710px) {
            width: calc(100% - 40px);
        }
        @media screen and (min-width: 45.5em) and (max-width: 64em) {
            width: calc(100% - 5rem);
        }
        .btn-group {
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width: 768px) {
                justify-content: space-between;
            }
        }
        .form-text {
            margin-top: 32px;
            width: 100%;
            @media screen and (max-width: 710px) {
                align-self: center;
                & ~ h2 .required_text {
                    display: none;
                }
            }
        }

        & + input[type='submit'] {
            margin: 1rem 1rem 5rem 0;
            font-weight: 600;
            cursor: pointer;
        }

        h2 .required_text {
            position: absolute;
            right: 2%;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
            top: 30px;
            &:before {
                content: '';
                position: relative;
                display: inline-block;
                width: 8px;
                height: 20px;
                background-color: ${props => props.theme.colors.primaryYellow};
                margin-right: 5px;
                top: 5px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .flex-row {
            display: flex;
            flex-direction: row;
        }

        h2 {
            font-size: 30px;
            align-self: flex-start;
        }
    }

    .terms-conditions-text {
        font-size: 12px;
        margin-bottom: 10px;
    }
`;

function EFTForm({ onSubmit, submitRef, prevBtnRef, ...props }) {
    const {
        register,
        handleSubmit,
        errors,
        getValues,
        control,
    } = useForm({ defaultValues: { ...props.eftForm } });
    const [hasAgreedToTerms, setHasAgreedToTerms] = useState(
        props.hasAgreedToTerms
    );
    return (
        <StyledEFTForm onSubmit={handleSubmit(onSubmit)}>
            <div className="etf-info-primary">
                <p className="form-text">
                    Autodraft is the required method of payment to establish a
                    Fleet Account. Please include your EFT information below.
                </p>
                <p>
                    Complete the below with your banking information to always
                    stay up to date on invoice payments by using electronic
                    auto-draft.
                </p>
                <h2>
                    Electronic Funds Transfer Information{' '}
                    <strong className="required_text">Required</strong>
                </h2>
                <div className="flex-row">
                    <HookFormInput
                        name="bankaccountno"
                        register={register}
                        hasError={errors.bankaccountno && true}
                        errorText={
                            errors.bankaccountno && errors.bankaccountno.message
                        }
                        label="Bank Account #"
                        type="number"
                        required={true}
                        value={props.bankaccountno && props.bankaccountno}
                        defaultValue={props.eftForm.bankaccountno}
                    ></HookFormInput>
                    <HookFormInput
                        name="confirmbankaccountno"
                        register={register}
                        hasError={errors.confirmbankaccountno && true}
                        errorText={
                            errors.confirmbankaccountno &&
                            errors.confirmbankaccountno.message
                        }
                        label="Confirm Account #"
                        type="number"
                        required={true}
                        value={
                            props.confirmbankaccountno &&
                            props.confirmbankaccountno
                        }
                        validate={value => {
                            if (value !== getValues()['bankaccountno']) {
                                return "Your Account Number Doesn't Match";
                            }
                        }}
                        defaultValue={props.eftForm.confirmbankaccountno}
                    ></HookFormInput>
                </div>
                <div className="flex-row">
                    <HookFormInput
                        name="bankName"
                        register={register}
                        hasError={errors.bankName && true}
                        errorText={errors.bankName && errors.bankName.message}
                        label="Bank Name"
                        required={true}
                        value={props.bankName && props.bankName}
                        defaultValue={props.eftForm.bankName}
                    ></HookFormInput>
                    <HookFormInput
                        name="bankroutingno"
                        register={register}
                        hasError={errors.bankroutingno && true}
                        errorText={
                            errors.bankroutingno && errors.bankroutingno.message
                        }
                        label="Bank Routing #"
                        type="number"
                        required={true}
                        value={props.bankroutingno && props.bankroutingno}
                        defaultValue={props.eftForm.bankroutingno}
                        validate={value => {
                            if (value.length !== 9) {
                                return 'Bank Routing # must be 9 digits';
                            }
                        }}
                    ></HookFormInput>
                </div>
                <div className="flex-row">
                    <HookFormInput
                        name="bankaddress"
                        register={register}
                        hasError={errors.bankaddress && true}
                        errorText={
                            errors.bankaddress && errors.bankaddress.message
                        }
                        label="Bank Address"
                        required={false}
                        value={props.bankaddress && props.bankaddress}
                        defaultValue={props.eftForm.bankaddress}
                    ></HookFormInput>
                    <HookFormInput
                        name="city"
                        register={register}
                        hasError={errors.city && true}
                        errorText={errors.city && errors.city.message}
                        label="City"
                        required={true}
                        value={props.city && props.city}
                        defaultValue={props.eftForm.city}
                    ></HookFormInput>
                </div>
                <div className="flex-row">
                    <HookFormSelect
                        name="state"
                        register={register}
                        hasError={errors.state && true}
                        errorText={errors.state && errors.state.message}
                        label="State"
                        required={true}
                        defaultValue={
                            props.eftForm.state && props.eftForm.state
                        }
                        control={control}
                        options={StateOptions}
                    ></HookFormSelect>
                    <HookFormInput
                        name="zipCode"
                        register={register}
                        hasError={errors.zipCode && true}
                        errorText={errors.zipCode && errors.zipCode.message}
                        label="Zip Code"
                        required={true}
                        max={999999}
                        value={props.zipCode && props.zipCode}
                        defaultValue={props.eftForm.zipCode}
                        validate={value => {
                            if (value.length < 5 || value.length > 6) {
                                return 'Enter a valid zip code';
                            }
                        }}
                        maskType="Number"
                    ></HookFormInput>
                </div>
                <div className="flex-row">
                    <HookFormInput
                        name="contactfirstname"
                        register={register}
                        hasError={errors.contactfirstname && true}
                        errorText={
                            errors.contactfirstname &&
                            errors.contactfirstname.message
                        }
                        label="Contact First Name"
                        required={false}
                        value={props.contactfirstname && props.contactfirstname}
                        defaultValue={props.eftForm.contactfirstname}
                    ></HookFormInput>
                    <HookFormInput
                        name="contactlastname"
                        register={register}
                        hasError={errors.contactlastname && true}
                        errorText={
                            errors.contactlastname &&
                            errors.contactlastname.message
                        }
                        label="Contact Last Name"
                        required={false}
                        value={props.contactlastname && props.contactlastname}
                        defaultValue={props.eftForm.contactlastname}
                    ></HookFormInput>
                </div>
                <div className="flex-row">
                    <HookFormInput
                        type="tel"
                        name="contactphone"
                        placeholder="###-###-####"
                        register={register}
                        pattern={/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/}
                        hasError={errors.contactphone && true}
                        errorText={
                            errors.contactphone && errors.contactphone.message
                        }
                        label="Phone"
                        value={props.contactphone && props.contactphone}
                        maskType="Phone"
                        defaultValue={props.eftForm.contactphone}
                    ></HookFormInput>
                    <HookFormInput
                        name="contactfax"
                        register={register}
                        placeholder="(###)###-####"
                        pattern={/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/}
                        hasError={errors.contactfax && true}
                        errorText={
                            errors.contactfax && errors.contactfax.message
                        }
                        label="Fax"
                        value={props.contactfax && props.contactfax}
                        maskType="Fax"
                        defaultValue={props.eftForm.contactfax}
                    ></HookFormInput>
                </div>
                <div className="flex-row terms-conditions-text">
                    Customer hereby agrees and acknowledges that: (1) Pilot may
                    withdraw funds from the above-referenced bank account via
                    electronic funds transfer at any time to collect on amounts
                    due and owing Pilot; (2) a $50 processing fee will be
                    debited to Customer’s accounts receivable for each transfer
                    returned to Pilot for non-sufficient funds or for any other
                    reason which would cause the funds to be unavailable for
                    drawing by Pilot; and (3) Pilot will be reimbursed, on
                    demand, by bank wire for the gross amount of each invoice
                    not paid by the electronic transfer of funds in addition to
                    the processing fee described above. This authority is to
                    remain in full force and effect until Pilot and the
                    above-referenced Depository have received written
                    notification from Customer of its termination in such time
                    and in such manner as to afford Pilot and the
                    above-referenced Depository a reasonable opportunity to act
                    on it.
                </div>
                <div className="flex-row">
                    <HookFormCheckbox
                        name="hasAgreedToTerms"
                        register={register}
                        label="I agree to terms"
                        hasError={errors.hasAgreedToTerms && true}
                        errorText={
                            errors.hasAgreedToTerms &&
                            errors.hasAgreedToTerms.message
                        }
                        required={true}
                        checked={props.eftForm.hasAgreedToTerms ? true : false}
                        onChange={e => {
                            setHasAgreedToTerms(e.target.checked);
                        }}
                        defaultChecked={props.eftForm.hasAgreedToTerms}
                    ></HookFormCheckbox>
                </div>
                <div className="btn-group">
                    <CreditSubmitButton
                        text="PREVIOUS"
                        buttonType="button"
                        buttonref={prevBtnRef}
                        onPrevClick={() => props.onPrevious(getValues())}
                    ></CreditSubmitButton>
                    <CreditSubmitButton
                        text="NEXT"
                        buttonref={submitRef}
                    ></CreditSubmitButton>
                </div>
            </div>
        </StyledEFTForm>
    );
}
//TODO: Add prop types
EFTForm.propTypes = {};

export default EFTForm;
