import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const StyledInput = styled.div`
    font-weight: bold;
    font-style: normal;
    position: relative;
    margin-right: 16px;
    margin-bottom: 28px;
    flex-basis: 0;
    flex-grow: 1;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 27px;
    label {
        // display: inline-block;
        text-align: left;
        color: #757575;
        font-family: 'Acumin-Pro';
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;

        span {
            color: #1d2521;
            font-family: 'Acumin-Pro';
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            margin-left: 17px;
        }
    }
`;

function HookFormLabel({ label, value }) {
    return (
        <StyledInput>
            <motion.label>
                {' '}
                {label + ':'}
                <motion.span>{value}</motion.span>
            </motion.label>
        </StyledInput>
    );
}

HookFormLabel.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
};

HookFormLabel.defaultProps = {
    label: 'Pass me a label',
    value: 'value',
};

export default HookFormLabel;
